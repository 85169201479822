<template>
  <div>
    <div class="title1">
      <div>
        <span class="title1-span">所属市场</span>
        <el-select
          v-model="FormValue.mk_id"
          clearable
          placeholder="请选择所属市场"
        >
          <el-option
            v-for="item in Form.marketid"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="GET_searchSegmentation(item.id)"
          >
          </el-option>
        </el-select>
      </div>
      <div class="title-margin">
        <span class="title1-span">所属分类</span>
        <el-select
          v-model="FormValue.type"
          clearable
          placeholder="请选择经营类目"
        >
          <el-option
            v-for="item in Form.type"
            :key="item.id"
            :label="item.segmentation_title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="title-margin">
        <span class="title1-span">摊位类型</span>
        <el-select
          v-model="FormValue.booth_type"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in Form.boothtype"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <router-link to="/home/addManages" class="title-margin">
        <el-button type="primary">新 增</el-button></router-link
      >
    </div>

    <div style="display: flex; align-items: center;margin-top:20px">
      <div class="title1">
        <div>
          <span class="title1-span">商户名称</span>
          <el-input
            style="width:217px"
            v-model="FormValue.name"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="title-margin">
          <span class="title1-span">摊位编号</span>
          <el-input
            style="width:217px"
            v-model="FormValue.booth_number"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="title-margin">
          <span class="title1-span">法定代表</span>
          <el-input
            style="width:217px"
            v-model="FormValue.legal_represent"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <el-button
          class="title-margin"
          type="primary"
          @click="GET_sellerShopList"
          >查 询</el-button
        >
        <el-upload
          class="uploadDemo"
          action="http://farmerstest.hengshunits.com/admin/v1/seller_excel_import"
          accept=".xlsx"
          :show-file-list="false"
          :http-request="uploadXlsxFile1"
          :before-upload="uploadXlsx"
        >
          <el-button type="primary">导 入</el-button>
        </el-upload>
      </div>
    </div>
    <el-table
      :data="tableDatas.data"
      border
      max-height="calc(100vh - 160px-40px)"
      style="width: 100%;margin-top:20px"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="seller_number" label="商户编码"> </el-table-column>
      <el-table-column prop="name" label="商户名称">
        <template slot-scope="scope">
          <el-link @click="dialogtrue(scope.row.id)" type="success">{{
            scope.row.name
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="comp_name" label="所属市场"> </el-table-column>
      <el-table-column prop="booth_numbers" label="所在摊位"> </el-table-column>
      <el-table-column prop="boothTypeName" label="摊位类型"> </el-table-column>
      <el-table-column prop="product_name" label="经营类型"> </el-table-column>
      <el-table-column prop="legal_represent" label="法定代表人">
      </el-table-column>
      <el-table-column prop="tel" label="手机号码"> </el-table-column>
      <el-table-column label="经营户证照">
        <template slot-scope="scope">
          <img
            :src="scope.row.business_license_img"
            style="width:100%;height:100px"
            alt=""
            @click="$refs.img.previewImage(scope.row.business_license_img)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="address" label="经营商品"> </el-table-column>
      <el-table-column prop="address" label="台账记录"> </el-table-column>
      <el-table-column prop="address" label="检测记录"> </el-table-column>
      <el-table-column prop="address" label="绑定设备"> </el-table-column>
      <el-table-column prop="Alipay_collection_code" label="支付宝">
        <template slot-scope="scope">
          <img
            :src="scope.row.Alipay_collection_code"
            alt=""
            style="width:100%;height:100px"
            @click="$refs.img.previewImage(scope.row.Alipay_collection_code)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="wechat_collection_code" label="微信">
        <template slot-scope="scope">
          <img
            :src="scope.row.wechat_collection_code"
            alt=""
            style="width:100%;height:100px"
            @click="$refs.img.previewImage(scope.row.wechat_collection_code)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="wechat_collection_code" label="微信二维码">
        <template slot-scope="scope">
          <img
            :src="scope.row.wechat_pay_url"
            alt=""
            style="width:100%;height:100px"
            @click="$refs.img.previewImage(scope.row.wechat_pay_url)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="mf_title" label="经营状态"> </el-table-column>
      <el-table-column label="审核">
        <template slot-scope="scope">
          <el-link @click="DialogVisible(scope.row)">{{
            scope.row.statusName
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="alter(scope.row)"
            >修 改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDatas.total"
      >
      </el-pagination>
    </div>

    <!-- 审核 -->
    <el-dialog
      title="经营户信息审核"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="betten">
        <b>所在市场:</b>
        <b>{{ examine.comp_name }}</b>
      </div>
      <div class="betten">
        <b>摊位类型:</b>
        <b>{{ examine.boothTypeName }}</b>
      </div>
      <div class="betten">
        <b>所在摊位:</b>
        <b>{{ examine.booth_numbers }}</b>
      </div>
      <div class="betten">
        <b>店铺名称:</b>
        <b>{{ examine.name }}</b>
      </div>
      <div class="betten">
        <b>统一社会信用代码:</b>
        <b>{{ examine.reg_id }}</b>
      </div>
      <div class="betten">
        <b>经营类型:</b>
        <b>{{ examine.product_name }}</b>
      </div>
      <div class="betten">
        <b>经营证照:</b>
      </div>
      <div class="betten">
        <img
          style="width:100px;height:100px"
          :src="examine.booth_imgs"
          alt=""
        />
        <img
          style="width:100px;height:100px"
          :src="examine.business_license_img"
          alt=""
        />
        <img
          style="width:100px;height:100px"
          :src="examine.permit_img"
          alt=""
        />
      </div>
      <div class="betten">
        <el-radio v-model="examine.statusName" label="通过">通过</el-radio>
        <el-radio v-model="examine.statusName" label="不通过">不通过</el-radio>
      </div>
      <div class="betten">
        <el-input
          type="textarea"
          resize="none"
          placeholder="请输入内容"
          v-model="examine.textarea"
        >
        </el-input>
      </div>
      <div style="text-align:right">
        <el-button @click="addList" type="primary">确 认</el-button>
      </div>
    </el-dialog>
    <!-- 经营户详细信息 大表格 -->
    <el-dialog :visible.sync="dialog" title="经营户详细信息" width="70%" center>
      <div class="addManageInfo">
        <div class="add_top">
          <div class="left" style="display:flex">
            <div>
              <img
                src="https://www.wulihub.com.cn/gc/qVRawn/images/%E7%BB%8F%E8%90%A5%E6%88%B7%E8%AF%A6%E7%BB%86%E4%BF%A1%E6%81%AF/u1060.png"
                alt=""
                style="width:150px;height:230px"
              />
            </div>
            <div class="right_text">
              <div class="rt" style="font-size:18px;font-weight:800">
                姓名:张三
              </div>
              <div class="rt">电话:13666666666</div>
              <div class="rt">经营商品:43种</div>
              <div class="rt">台账记录:43种</div>
              <div class="rt">检测记录:43种</div>
              <div class="rt">监测合格率:100%</div>
              <div class="rt">电子秤:2台</div>
              <div class="rt">消费者看板:2台</div>
            </div>
          </div>
          <div class="middle">
            <!-- <div class="m1">经营数据</div> -->
            <div class="rt" style="font-size:18px;font-weight:800">
              经营数据
            </div>
            <div class="m2">
              <div class="item">
                <div class="num">543元</div>
                <div class="info">今天营业额</div>
              </div>
              <div class="item">
                <div class="num">543元</div>
                <div class="info">今天营业额</div>
              </div>
              <div class="item">
                <div class="num">543元</div>
                <div class="info">今天营业额</div>
              </div>
            </div>
            <div style="font-size:18px;font-weight:800;margin-top:10px">
              诚信管理
            </div>
            <div class="m4">
              <div class="item">
                <div class="top">100%</div>
                <div class="bottom">好评</div>
              </div>
              <div class="item">
                <div class="top">100%</div>
                <div class="bottom">投诉</div>
              </div>
            </div>
          </div>
          <div class="right">
            <img
              src="https://www.wulihub.com.cn/gc/qVRawn/images/%E7%BB%8F%E8%90%A5%E6%88%B7%E8%AF%A6%E7%BB%86%E4%BF%A1%E6%81%AF/u1499.png"
              alt=""
            />
          </div>
        </div>
        <div class="add_bottom">
          <el-tabs v-model="activeName">
            <el-tab-pane label="经营商品" name="1" class="tab1">
              <div class="items">
                <div class="item" v-for="(item, index) in 7" :key="index">
                  <div class="top">
                    <div class="left">
                      <img
                        src="https://www.wulihub.com.cn/gc/qVRawn/images/%E7%BB%8F%E8%90%A5%E6%88%B7%E8%AF%A6%E7%BB%86%E4%BF%A1%E6%81%AF/u1092.png"
                        alt=""
                        style="width:150px;height:110px"
                      />
                    </div>
                    <div class="right">
                      <div class="r1">大白菜</div>
                      <div class="r2">进货台账: 15批次</div>
                      <div class="r2">检测记录: 15次</div>
                      <div class="r2">检测合格率: 100%</div>
                    </div>
                  </div>
                  <div>
                    <el-table
                      :data="tableData"
                      stripe
                      border-top
                      style="width: 100%"
                      height="240px"
                    >
                      <el-table-column prop="date" label="销售数据">
                      </el-table-column>
                      <el-table-column prop="name" label="昨天">
                      </el-table-column>
                      <el-table-column prop="address" label="累积">
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="台账记录" name="2">
              <el-row :gutter="12">
                <el-col :span="8">
                  <el-card shadow="always" style="text-align:center">
                    <div>台账批次</div>
                    <div>15次</div>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card shadow="hover" style="text-align:center">
                    <div>商品</div>
                    <div>15种</div>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card shadow="never" style="text-align:center">
                    <div>供应商</div>
                    <div>2家</div>
                  </el-card>
                </el-col>
              </el-row>
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column label="缩略图">
                  <slot>
                    <img
                      src="https://www.wulihub.com.cn/gc/qVRawn/images/%E7%BB%8F%E8%90%A5%E6%88%B7%E8%AF%A6%E7%BB%86%E4%BF%A1%E6%81%AF/u1092.png"
                      alt=""
                      style="width:150px;"
                    />
                  </slot>
                </el-table-column>
                <el-table-column prop="name" label="进货批次">
                </el-table-column>
                <el-table-column prop="address" label="商品名称">
                </el-table-column>
                <el-table-column prop="address" label="进货数量">
                </el-table-column>
                <el-table-column prop="address" label="供应商">
                </el-table-column>
                <el-table-column prop="address" label="进货时间">
                </el-table-column>
                <el-table-column prop="address" label="登记时间">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="检测记录" name="3"
              ><el-row :gutter="12">
                <el-col :span="6">
                  <el-card shadow="always" style="text-align:center">
                    <div>检测批次</div>
                    <div>15次</div>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="hover" style="text-align:center">
                    <div>检测商品</div>
                    <div>20种</div>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="never" style="text-align:center">
                    <div>检测合格率</div>
                    <div>2家</div>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="never" style="text-align:center">
                    <div>检测不合格商品</div>
                    <div>1种</div>
                  </el-card>
                </el-col>
              </el-row>
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column label="缩略图">
                  <slot>
                    <img
                      src="https://www.wulihub.com.cn/gc/qVRawn/images/%E7%BB%8F%E8%90%A5%E6%88%B7%E8%AF%A6%E7%BB%86%E4%BF%A1%E6%81%AF/u1092.png"
                      alt=""
                      style="width:150px;"
                    />
                  </slot>
                </el-table-column>
                <el-table-column prop="name" label="进货批次">
                </el-table-column>
                <el-table-column prop="address" label="商品名称">
                </el-table-column>
                <el-table-column prop="address" label="进货数量">
                </el-table-column>
                <el-table-column prop="address" label="供应商">
                </el-table-column>
                <el-table-column prop="address" label="进货时间">
                </el-table-column>
                <el-table-column prop="address" label="登记时间">
                </el-table-column> </el-table
            ></el-tab-pane>
            <el-tab-pane label="设备信息" name="4" class="tab4">
              <div class="title">商户设备管理</div>
              <div class="content">
                <div class="left">
                  <div class="left_1">
                    <img
                      src="https://www.wulihub.com.cn/gc/qVRawn/images/%E7%BB%8F%E8%90%A5%E6%88%B7%E8%AF%A6%E7%BB%86%E4%BF%A1%E6%81%AF/u1291.png"
                      alt=""
                    />
                    <div>智能溯源电子秤</div>
                    <div>计量30KG</div>
                    <div>电子秤机器码 5615464564</div>
                    <div>状态 在线</div>
                  </div>
                  <div class="right_1">
                    <div>安装时间:2021-06-15</div>
                    <div></div>
                    <div>累计交易额:25.53万元</div>
                    <div>累计交易量:5841笔</div>
                    <div>累计重量:2548KG</div>
                    <div class="line"></div>
                    <div>今天交易额:2414元</div>
                    <div>今天交易量:35笔</div>
                    <div>今日交易重量:548KG</div>
                  </div>
                </div>
                <div class="right">
                  <img
                    src="https://www.wulihub.com.cn/gc/qVRawn/images/%E7%BB%8F%E8%90%A5%E6%88%B7%E8%AF%A6%E7%BB%86%E4%BF%A1%E6%81%AF/u1291.png"
                    alt=""
                  />
                  <div>智能溯源电子秤</div>
                  <div>计量60KG</div>
                  <div>电子秤机器码 5615464564</div>
                  <div>状态 离线</div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="证件信息" name="5">证件信息</el-tab-pane>
            <el-tab-pane label="诚信记录" name="6">诚信记录</el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import { GETsellerShopList, GetsellerCheck } from '../../../api/manage'
import {
  getmarket,
  GETsearchSegmentation,
  GETsearchBoothType
} from '../../../api/layout'
import { uploadXlsxList } from '../../../utile/upload'
export default {
  name: 'managetabal',
  data () {
    return {
      tableDatas: '', // 表格数据
      dialog: false,
      ID: '',
      // 查看
      centerDialogVisible: false,
      examine: {
        comp_name: '',
        boothTypeName: '',
        booth_numbers: '',
        name: '',
        reg_id: '',
        product_name: '',
        statusName: '',
        booth_imgs: '',
        business_license_img: '',
        permit_img: '',
        textarea: ''
      },
      Form: {
        marketid: '', // 所属市场数据
        type: '', // 经营类目数据
        boothtype: '' // 摊位类型
      },
      FormValue: {
        page: '',
        limit: '',
        mk_id: '', // 所属市场id
        type: '', // 经营类目id
        booth_type: '', // 摊位类型id
        name: '', // 商户名称
        booth_number: '', // 摊位编号
        legal_represent: '' // 法定代表人
      },
      activeName: '1',
      tableData: []
    }
  },
  methods: {
    //   表格数据
    GET_sellerShopList () {
      GETsellerShopList(this.FormValue)
        .then(res => {
          if (res.code === 200) {
            this.tableDatas = res.data
          }
        })
        .catch(err => {
          this.$message.error(`${err.msg}`)
        })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.FormValue.limit = val
      this.GET_sellerShopList()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.FormValue.page = val
      this.GET_sellerShopList()
    },
    // 所属市场
    get_market () {
      getmarket().then(res => {
        this.Form.marketid = res.data
      })
    },
    // 经营类目
    GET_searchSegmentation (id) {
      this.FormValue.type = ''
      GETsearchSegmentation(id).then(res => {
        this.Form.type = res.data
      })
    },
    //  摊位类型
    GET_searchBoothType () {
      GETsearchBoothType().then(res => {
        this.Form.boothtype = res.data
      })
    },
    // 导入文件
    uploadXlsx (file) {
      const isShow = file.name.split('.')
      const status = isShow[isShow.length - 1] === 'xlsx'
      if (!status) {
        this.$message.error('只能上传xlsx的格式的表格!')
      }
      return status
    },
    uploadXlsxFile1 (param) {
      uploadXlsxList(param).then(res => {
        if (res.data.code === 200) {
          this.$message.success(`${res.data.data}`)
          this.GET_sellerShopList({ page: this.page, limit: this.limit })
        } else {
          this.$message.error(`${res.data.msg}`)
        }
      })
    },
    // 审核
    DialogVisible (row) {
      this.centerDialogVisible = true
      this.ID = row.id
      this.examine.comp_name = row.comp_name
      this.examine.boothTypeName = row.boothTypeName
      this.examine.booth_numbers = row.booth_numbers
      this.examine.name = row.name
      this.examine.reg_id = row.reg_id
      this.examine.product_name = row.product_name
      this.examine.booth_imgs = row.booth_imgs
      this.examine.business_license_img = row.business_license_img
      this.examine.permit_img = row.permit_img
      this.examine.statusName =
        row.statusName === '未审核' ? '通过' : row.statusName
    },
    // //  查询
    // submit () {
    //   this.GET_sellerShopList()
    // },
    addList () {
      if (this.examine.statusName === '不通过' && !this.examine.textarea) {
        this.$message.error('必须要写不通过的原因哦！')
        return
      }
      const data = {
        id: this.ID,
        status: this.examine.statusName === '通过' ? 1 : 2,
        info: this.examine.textarea
      }
      GetsellerCheck(data)
        .then(res => {
          this.centerDialogVisible = false
          this.$message.success(`${res.data}`)
          this.examine = {
            comp_name: '',
            boothTypeName: '',
            booth_numbers: '',
            name: '',
            reg_id: '',
            product_name: '',
            statusName: '',
            booth_imgs: '',
            business_license_img: '',
            permit_img: '',
            textarea: ''
          }
        })
        .catch(err => {
          this.$message.error(`${err.msg}`)
        })
    },
    // 经营户详细信息
    dialogtrue (id) {
      console.log(id)
      //   this.dialog = true
    },
    alter (row) {
      this.$router.replace({ name: 'addManages', query: { id: row.id } })
    }
  },
  mounted () {
    this.GET_sellerShopList()
    this.GET_searchBoothType()
    this.get_market()
  }
}
</script>

<style lang="less" scoped>
.title1 {
  width: 70%;
  display: flex;
  .title-margin {
    margin-left: 20px;
  }
  .title1-span {
    width: 110px;
    margin-right: 20px;
  }
}
.footers {
  text-align: right;
  margin-top: 10px;
}
.betten {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 80px;
}
.addManageInfo {
  box-sizing: border-box;
  .add_top {
    display: flex;
    justify-content: space-around;
    .left {
      padding-right: 40px;
      margin-right: 10px;
      .right_text {
        margin-left: 30px;
        .rt {
          line-height: 30px;
        }
      }
    }
    .middle {
      .m1 {
        font-weight: 800;
      }
      .m2 {
        display: flex;
        .item {
          margin-right: 10px;
          margin-top: 10px;
          padding: 20px 10px;
          border: 1px solid #c1c1c1;
          .num {
            font-size: 14px;
            text-align: center;
            line-height: 30px;
          }
          .info {
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
      .m3 {
        margin-top: 10px;
        font-weight: 800;
      }
      .m4 {
        display: flex;
        margin-top: 20px;
        .item {
          margin-left: 30px;
          .top {
            font-size: 14px;
          }
          .bottom {
            font-weight: 800;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .add_bottom {
    .tab1 {
      .items {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 30%;
          border: 1px solid #8a8a8a;
          margin-left: 30px;
          margin-top: 30px;
          .top {
            display: flex;
            justify-content: space-around;
            .right {
              line-height: 30px;
              .r1 {
                font-weight: bold;
              }
            }
          }
        }
        &::after {
          content: '';
          flex: 1;
        }
      }
    }
    .tab4 {
      .title {
      }
      .content {
        display: flex;
        justify-content: space-around;
        .left {
          display: flex;
          justify-content: space-around;
          border: solid 1px #000;
          padding: 30px;
          width: 40%;
          .left_1 {
            img {
              width: 256px;
            }
          }
        }
        .right {
          border: solid 1px #000;
          padding: 30px;
          width: 40%;
          img {
            width: 256px;
          }
        }
      }
    }
  }
}
/deep/ .el-tabs__header {
  margin: 0;
}
.uploadDemo {
  display: inline-block;
  margin-left: 20px;
  border: none;
}
.uploadDemo /deep/.el-upload {
  border: none;
}
</style>
